import axios from 'axios';
import common from './common';
import qs from 'qs'
/*
const http = axios.create({
    timeout: 15000
  });
  
  // request拦截器
  http.interceptors.request.use(function(config){
      config.data = config.data?config.data: {}
      // 把token添加到请求头,每次请求都带上
      config.headers = {
        //'Content-type': 'application/json',
        //'authorization': store.state.token==null?"":store.state.token
      }
      return config;
  })

  */
export default class ServiceNew {

  static loadServiceList = async (vm, obj) => {
    const response = await axios.post(common.resources.service.getContentFormPid, qs.stringify(obj));
    return response;
  }

}