<template>
  <div class="container">
    <van-nav-bar
      title="车辆服务认证"
      :fixed="true"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    <div style="height: 40px"></div>

    <van-cell-group center :border="true">
      <template #title>
        <div class="g-title-item">
          <div>上户</div>
          <div>
            <van-checkbox
              checked-color="#F3C301"
              v-model="carSHChecked"
              icon-size="16"
              shape="square"
              @change="changeSH"
            >
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? activeIcon : inactiveIcon"
                />
              </template>
            </van-checkbox>
          </div>
        </div>
      </template>
      <van-cell>
        <van-checkbox-group
          checked-color="#F3C301"
          class="service-box1"
          @change="changeSHList"
          v-model="serviceOptions"
        >
          <div
            class="service-item1"
            v-for="(item, index) in subCarServiceList"
            :key="index"
          >
            <van-checkbox icon-size="16" :name="item.id"
              >{{ item.zh_name }}
            </van-checkbox>
          </div>
        </van-checkbox-group>
      </van-cell>
    </van-cell-group>

    <van-cell-group center :border="true">
      <template #title>
        <div class="g-title-item">
          <div>过户</div>
          <div>
            <van-checkbox
              checked-color="#F3C301"
              v-model="carGHChecked"
              icon-size="16"
              shape="square"
            >
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? activeIcon : inactiveIcon"
                />
              </template>
            </van-checkbox>
          </div>
        </div>
      </template>
      <!--
      <van-cell v-show="carGHChecked">
        <van-checkbox-group
          checked-color="#F3C301"
          class="service-box1"
          v-model="serviceOptions"
        >
          <div class="service-item1">
            <van-checkbox icon-size="16" :name="1" shape="square"
              >解押</van-checkbox
            >
          </div>
          <div class="service-item1">
            <van-checkbox icon-size="16" :name="2" shape="square"
              >提档</van-checkbox
            >
          </div>
          <div class="service-item1">
            <van-checkbox icon-size="16" :name="3" shape="square"
              >过户</van-checkbox
            >
          </div>
        </van-checkbox-group>
      </van-cell>
      -->
    </van-cell-group>

    <van-cell-group center :border="true">
      <template #title>
        <div class="g-title-item">
          <div>年审</div>
          <div>
            <van-checkbox
              checked-color="#F3C301"
              v-model="carNSChecked"
              icon-size="16"
              shape="square"
            >
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? activeIcon : inactiveIcon"
                />
              </template>
            </van-checkbox>
          </div>
        </div>
      </template>
    </van-cell-group>
    <van-cell-group center :border="true">
      <template #title>
        <div class="g-title-item">
          <div>补办证书</div>
          <div>
            <van-checkbox
              checked-color="#F3C301"
              v-model="carBBZSChecked"
              icon-size="16"
              shape="square"
            >
              <template #icon="props">
                <img
                  class="img-icon"
                  :src="props.checked ? activeIcon : inactiveIcon"
                />
              </template>
            </van-checkbox>
          </div>
        </div>
      </template>
    </van-cell-group>

    <van-cell
      :border="false"
      center
      is-link
      v-if="carServiceStatus != 1"
      @click="showAreaPopup"
    >
      <template #title>
        <div class="g-title-box">
          <div class="title">业务范围</div>
          <div class="des">(设置之后只能推送范围内订单)</div>
        </div>
      </template>
      <template #right-icon>
        <van-icon name="plus" />
      </template>
    </van-cell>
    <van-cell :border="false" center is-link v-else>
      <template #title>
        <div class="g-title-box">
          <div class="title">业务范围</div>
          <div class="des">(设置之后只能推送范围内订单)</div>
        </div>
      </template>
    </van-cell>
    <van-cell v-if="addressList.length" :border="false">
      <template #title>
        <div class="p-cell">
          <div v-for="(item, index) in addressList" :key="index">
            <van-tag
              color="#f3c302"
              class="space"
              plain
              v-if="carServiceStatus != 1"
              round
              closeable
              size="medium"
              type="primary"
              @close="removeArea(item.value)"
              :item="item"
            >
              {{ item.value }}
            </van-tag>
            <van-tag
              color="#f3c302"
              class="space"
              plain
              v-else
              round
              :closeable="false"
              size="medium"
              type="primary"
              :item="item"
            >
              {{ item.value }}
            </van-tag>
          </div>
        </div>
      </template>
    </van-cell>

    <van-cell-group center :border="false" title="名片">
      <van-cell>
        <van-uploader
          :after-read="afterReadA"
          :before-delete="beforeDeleteA"
          v-model="fileA"
          :max-count="1"
          multiple
          :deletable="carServiceStatus != 1"
          :show-upload="carServiceStatus != 1"
        >
        </van-uploader>
      </van-cell>
    </van-cell-group>

    <van-cell-group center :border="false">
      <template #title>
        <div class="g-title-box">
          <div class="title">附件</div>
          <div class="des">工作牌，工作证明，技能证书，技能荣誉证书等等(可上传多张)</div>
        </div>
      </template>

      <van-cell>
        <van-uploader
          :after-read="afterReadB"
          :before-delete="beforeDeleteB"
          v-model="fileB"
          multiple
          :deletable="carServiceStatus != 1"
          :show-upload="carServiceStatus != 1"
        />
      </van-cell>
    </van-cell-group>

    <!-- 区域选择弹出框--->
    <van-popup v-model="showArea" position="bottom">
      <van-area
        title="选择业务范围"
        :area-list="areaList"
        visible-item-count="4"
        :value="code"
        @confirm="ensureArea"
        @cancel="closeAreaPopup"
      />
    </van-popup>

    <div class="button-box">
      <van-button
        v-if="carServiceStatus == 0"
        round
        block
        type="info"
        color="#F3C301"
        @click="authentication"
      >
        <span>发起认证</span></van-button
      >
      <van-button
        v-else-if="carServiceStatus == 1"
        round
        block
        type="info"
        color="#F3C301"
      >
        <span>认证中</span></van-button
      >
      <van-button
        v-else-if="carServiceStatus == 2"
        round
        block
        type="info"
        color="#F3C301"
        @click="authentication"
      >
        <span>已认证(重新认证)</span></van-button
      >
      <van-button
        v-else
        round
        block
        type="info"
        color="#F3C301"
        @click="authentication"
      >
        <span>发起认证</span></van-button
      >
    </div>
    <div class="kbox"></div>
  </div>
</template>

<script>
import AreaList from "@/utils/area";
import ServiceNew from "@/api/serviceNew";
import UserService from "@/api/user";
import BaseService from "@/api/base";
import { Toast } from "vant";
//import { Toast } from "vant";
export default {
  data() {
    return {
      carServiceStatus: "",
      carSHChecked: false,
      carGHChecked: false, //过户
      carNSChecked: false, //年审
      carBBZSChecked: false, //补办证书
      carServiceList: null,
      subCarServiceList: null,
      subServiceOptions: [], //选择的详细服务
      serviceOptions: [], //选择的详细服务
      showArea: false,
      areaList: AreaList,
      code: "500112",
      addressList: [], // 区域
      fileA: [], //身份证图片
      fileAPath: "",
      fileB: [], //附件
      fileBPath: "",

      activeIcon: require("@/assets/img/checked.png"),
      inactiveIcon: require("@/assets/img/check.png"),
    };
  },

  async beforeMount() {
    this.getStaffAuth();
    this.getStaffAuthInfo();
    this.loadCarService();
    this.loadSubCarService(21); //加载上户子项
  },
  methods: {
    async loadCarService() {
      var params = {
        pid: 1,
        level: 1,
        token : this.$cookie.get("token")
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
      };
      const resp1 = await ServiceNew.loadServiceList(this, params);
      if (resp1.data.code == 10000) {
        this.carServiceList = resp1.data.data;
      }
    },

    async loadSubCarService(pid) {
      var params = {
        pid: pid,
        level: 4,
        token : this.$cookie.get("token")
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
      };
      const resp1 = await ServiceNew.loadServiceList(this, params);
      if (resp1.data.code == 10000) {
        console.log("AA");
        this.subCarServiceList = resp1.data.data;
        var list = resp1.data.data;
        var array = new Array();
        list.forEach(function(item) {
          array.push(item.id);
        });
        this.subServiceOptions = array;
      }
    },

    async sendSMS() {
      var params = {
        type: 2,
        phone: this.phone,
      };
      const resp = await UserService.sendCode(this, params);
      console.log(resp);
    },

    //认证信息详情
    async getStaffAuthInfo() {
      var that = this;
      //得到用户认证信息
      var token = this.$cookie.get("token");
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      var params = {
        token: token,
        orderType: 1,
      };
      const resp1 = await UserService.getStaffAuthInfo(this, params);
      if (resp1.data.code == 10000) {
        if (resp1.data.data != null) {
          console.log(resp1.data.data);

          var address = resp1.data.data.order_round;
          var addressArray = new Array();
          address.forEach(function(item) {
            if (item != "") {
              addressArray.push({
                value: item,
              });
            }
          });
          that.addressList = addressArray;

          var ooList = resp1.data.data.order_service;
          if (ooList.includes("21")) {
            that.carSHChecked = true;
          }
          if (ooList.includes("22")) {
            that.carGHChecked = true;
          }
          if (ooList.includes("24")) {
            that.carBBZSChecked = true;
          }
          if (ooList.includes("26")) {
            that.carNSChecked = true;
          }
          var ooNewList = new Array();
          ooList.forEach(function(item) {
            if (item != "21" && item != "22" && item != "24" && item != "26") {
              ooNewList.push(parseInt(item));
            }
          });

          that.serviceOptions = ooNewList;
          console.log(that.serviceOptions);

          var fileA = new Array();
          if (resp1.data.data.skill_img != "") {
            fileA.push({
              url: resp1.data.data.skill_img,
            });
          }
          that.fileA = fileA;
          that.fileAPath = resp1.data.data.skill_img;
          var fileB = new Array();
          var fileBOld = resp1.data.data.annex_img;
          var fileBPath = "";
          fileBOld.forEach(function(item) {
            if (item != "") {
              fileB.push({
                url: item,
              });
              fileBPath = fileBPath + item + "|";
            }
          });
          that.fileB = fileB;
          that.fileBPath = fileBPath.substring(0, fileBPath.length - 1);
        }
      }
    },

    //认证信息
    async getStaffAuth() {
      var that = this;
      //得到用户认证信息
      var token = this.$cookie.get("token");
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      var params = {
        token: token,
      };
      const resp1 = await UserService.getStaffAuth(this, params);
      if (resp1.data.code == 10000) {
        var list = resp1.data.data;
        list.forEach(function(item) {
          if (item.id == 1) {
            that.carServiceStatus = item.checkServiceStatus;
          }
        });
      }
    },

    //认证
    async authentication() {
      var serviceList = new Array();
      console.log(this.serviceOptions);
      if (this.carSHChecked) {
        serviceList.push("21");
      }
      if (this.carGHChecked) {
        serviceList.push("22");
      }
      if (this.carNSChecked) {
        serviceList.push("26");
      }
      if (this.carBBZSChecked) {
        serviceList.push("24");
      }
      var ooList = this.serviceOptions;
      ooList.forEach(function(item) {
        serviceList.push(item);
      });
      var addressList = this.addressList;
      var order_service = "";
      if (serviceList.length < 1) {
        Toast("请选择服务");
        return false;
      }
      console.log("发起审批");
      serviceList.forEach(function(item) {
        order_service = order_service + item + "|";
      });

      var order_round = "";
      console.log(addressList);
      if (addressList.length < 1) {
        Toast("请选择服务范围");
        return false;
      }
      addressList.forEach(function(item) {
        order_round = order_round + item.value + "|";
      });
      var filePath = this.fileBPath;
      filePath = filePath.substring(1, filePath.length);
      console.log(filePath);
      console.log(order_service);

      const params = {
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        code: this.phoneCode, //手机验证码
        orderService: order_service.substring(0, order_service.length - 1),
        orderRound: order_round,
        orderType: 1, //汽车服务
        cardImg: this.fileAPath,
        skillImg: this.fileAPath,
        annexImg: this.fileBPath,
      };
      const resp = await UserService.updateStaffAuthInfo(this, params);
      console.log(resp);
      if (resp.data.code == 10000) {
        this.$router.push("/");
      } else {
        Toast(resp.data.msg);
      }
    },
    goHome: function() {
      this.$router.push("/");
    },
    goBack: function() {
      this.$router.go(-1);
    },
    showAreaPopup: function() {
      this.showArea = true;
    },
    closeAreaPopup: function() {
      this.showArea = false;
    },

    removeArea: function(value) {
      var list = this.addressList.filter((item) => item.value != value);
      this.addressList = list;
    },
    ensureArea: function(val) {
      var data = val;
      var area = "";
      data.forEach((e) => {
        area = area + e.name;
      });
      area = data[0].name + "-" + data[1].name + "-" + data[2].name;
      var flag = false;
      this.addressList.forEach(function(el) {
        if (el.value == area) {
          flag = true;
        }
      });
      if (!flag) {
        this.addressList.push({ value: area });
      }
      console.log(this.addressList);
      this.closeAreaPopup();
    },

    async afterReadA(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("image", file.file);
      const resp = await BaseService.uploadImg(this, formData);
      if (resp.data.code == 10000) {
        this.fileAPath = "https://jsagency.cn/" + resp.data.data.imgUrl;
        file.status = "done";
      }
    },
    beforeDeleteA: function(event) {
      this.fileAPath = null;
      this.fileA = [];
      console.log(event);
      console.log("aaa");
    },

    beforeDeleteB: function(event) {
      console.log(event);
      var fileBList = this.fileB;
      var fileBNewList = new Array();
      fileBList.forEach(function(item) {
        if (item.url != event.url) {
          fileBNewList.push(item);
        }
      });
      this.fileB = fileBNewList;
      var fileBPathNew = "";
      fileBNewList.forEach(function(item) {
        fileBPathNew = fileBPathNew + item.url + "|";
      });
      this.fileBPath = fileBPathNew.substring(0, fileBPathNew.length - 1);
      console.log(this.fileB);
      console.log(this.fileBPath);
    },
    async afterReadB(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("image", file.file);
      const resp = await BaseService.uploadImg(this, formData);
      if (resp.data.code == 10000) {
        this.fileBPath =
          this.fileBPath + "|" + "https://jsagency.cn/" + resp.data.data.imgUrl;
        file.status = "done";
      }
    },
     
    changeSH: function(event) {
      console.log(event);
      if (event == false) {
        this.serviceOptions = [];
      } else {
        //this.serviceOptions = this.subServiceOptions
      }
    },
    changeSHList: function(event) {
      if (event.length == 0) {
        this.carSHChecked = false;
      } else {
        this.carSHChecked = true;
      }
    },
  },
};
</script>

<style>
.van-uploader__preview {
  position: relative !important;
  margin: 0 !important;
  margin-top: 4px !important;
  cursor: pointer !important;
  width: 100% !important;
}
.van-uploader__preview-image {
  display: block !important;
  /* width: 80px; */
  width: 100% !important;
  height: 100% !important;
  /* height: 80px; */
  overflow: hidden !important;
}
.van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon {
  color: #f3c301 !important;
}

.van-checkbox__icon--disabled .van-icon {
  background-color: #fff !important;
  border-color: #f3c301 !important;
}
.van-uploader {
  width: 100% !important;
}
.van-uploader__wrapper {
  display: flex !important;
  flex-flow: row wrap !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
.labelClass {
  width: 150px;
}
.optional {
  background: #fafafa;
  font-size: 14px;
  color: #333;
  padding: 8px 16px;
}
.label-class {
  width: 60px !important;
  text-align-last: justify !important;
}
.container {
  background-color: #ffffff;
}
.van-cell-group__title {
  color: #000 !important;
  font-size: 14px !important;
}
.service-box {
  margin: 0px 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.service-box1 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.service-box .service-item {
  width: 30%;
  margin: 10px 0px;
}

.service-box1 .service-item1 {
  min-width: 104px;
  margin: 10px 0px;
  text-align-last: justify;
}
.service-box1 .service-item1 span {
  color: #333;
}

.service-box .service-item span {
  color: #333;
}
.space {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.p-cell {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.button-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 20px 40px 10px 40px;
}
.g-title-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.g-title-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.g-title-box .des {
  font-size: 10px;
  color: #ffa44d;
  margin-left: 6px;
  font-weight: 700;
}
.g-title-box .title {
  min-width: 30px;
}
</style>
